import React from 'react'
import dedupeArrayOfObjects from '../../utils/dedupeArrayOfObjects'
import styled from 'styled-components'
import Transitioned from '../Transitioned'
import NarrationUI from '../NarrationUI'
import { Typography } from '../StyleUtils'
import { AnimatePresence } from 'framer-motion'

const Subtitle = styled.div`
  max-width: 45rem;
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SubsWrapper = styled.div`
  min-height: ${({ ignoreMinHeight }) => ignoreMinHeight ? '0' : '9rem'};
  margin-bottom: ${( ignoreMargin ) => ignoreMargin ? '0' : '2rem'};
  display: flex;
  align-items: center;
  @media (max-height: 850px) {
    margin-top: ${( ignoreMargin ) => ignoreMargin ? '0' : '3rem'};
    min-height: ${({ ignoreMinHeight }) => ignoreMinHeight ? '0' : '7rem'};
    margin-bottom: 0;
    .narration-subs {
      font-size: 1.3rem !important;
    }
  }
`

export default function Subtitles({
  subtitles,
  activeSubtitles,
  onCTAClick,
  transitionDuration = 0.5,
  ignoreMinHeight,
  ignoreMargin,
}) {
  const renderedSubtitles = dedupeArrayOfObjects({ array: subtitles })
    .filter(({ id }) => id === activeSubtitles)
    .map(({ id, text }) => {
      return (
        <Transitioned key={id} duration={transitionDuration}>
          <Subtitle>
            <Typography className="narration-subs" pointerEventsNone>{text}</Typography>
            <NarrationUI id={id} onCTAClick={onCTAClick} />
          </Subtitle>
        </Transitioned>
      )
    })

  return (
    <SubsWrapper
      ignoreMinHeight={ignoreMinHeight}
      ignoreMargin={ignoreMargin}
    >
      <AnimatePresence key="subtitles" exitBeforeEnter>
        {renderedSubtitles}
      </AnimatePresence>
    </SubsWrapper>
  )
}