import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useGlobalState from '../../hooks/useGlobalState'
import useUpdateCurrentScene from '../../hooks/useUpdateCurrentScene'
import Narration from '../Narration'
import { createFullScreenFlex } from '../StyleUtils'

const SceneWrapper = createFullScreenFlex({ elementType: 'section' })

const FlexColumnSceneWrapper = styled(SceneWrapper)`
  flex-direction: column;
`

const INTRO_DELAY = 2000

export default function SelectNarration({ path }) {
  const [narrate, setNarrate] = useState(false)
  const timeoutRef = useRef(null)

  useUpdateCurrentScene()

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setNarrate(true)
    }, INTRO_DELAY)
    const timeout = timeoutRef.current
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <FlexColumnSceneWrapper>
      {narrate && <Narration />}
    </FlexColumnSceneWrapper>
  )
}