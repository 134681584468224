/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect } from 'react'
import useGlobalState from '../../hooks/useGlobalState'
import Subtitles from '../Subtitles'

export default function Narration() {
  const { subtitles, activeSubtitles, playNarration } = useGlobalState()

  useEffect(() => {
    // Add a timeout here to prevent race conditions between mounting
    // the narration state, and scene transitions
    setTimeout(() => {
      playNarration()
    }, 50)
  }, [playNarration])

  return <Subtitles subtitles={subtitles} activeSubtitles={activeSubtitles} />
}
