import { useCallback, useEffect, useMemo } from 'react'
import useGlobalState from './useGlobalState'

const leftHandKeys = [17, 9, 27, 32, 35, 45, 33, 34, 36, 18, 91, 20, 49, 50, 51, 52, 53, 81, 87, 69, 82, 84, 65, 83, 68, 70, 71, 90, 88, 67, 86, 66, 192, 113, 114, 115, 116, 117]
const rightHandKeys = [220, 110, 8, 37, 38, 39, 40, 54, 55, 56, 57, 48, 173, 61, 89, 85, 73, 79, 80, 219, 221, 163, 72, 74, 75, 76, 59, 222, 78, 77, 188, 190, 191, 118, 119, 120, 121, 122, 123]

const noop = () => { }

const BASELINE_AGITATION = 0.0
const AGITATION_ACCEL_INCREMENT = 0.015
const BASELINE_AGITATION_ACCELERATION = 0.0
const MAX_AGITATION_VALUE = 0.98

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

class AgitationManager {
  constructor({
    onAgitation = noop,
    agitatorKeys = [
      'left',
      'right',
    ],
    ignoreInput = false,
  } = {}) {
    this.frame = 1
    this.ignoreInput = ignoreInput
    this.agitators = {}
    agitatorKeys.forEach((key) => {
      this.agitators[key] = {
        vel: BASELINE_AGITATION,
        accel: BASELINE_AGITATION_ACCELERATION,
        value: 0,
        noiseOffset: 0,
      }
    })

    this.handleAgitation = onAgitation
  }

  agitate(key) {
    const agitatorPhysics = this.agitators[key]
    if (agitatorPhysics.value > MAX_AGITATION_VALUE) return
    // this is to ensure that we don't accelerate too much
    // and break the sound and / or light barrier
    this.handleAgitation(agitatorPhysics)
    agitatorPhysics.accel += AGITATION_ACCEL_INCREMENT
  }

  tick() {
    this.frame += 1
    if (this.ignoreInput) {
      Object.keys(this.agitators).forEach((key) => {
        const agitatorPhysics = this.agitators[key]
        agitatorPhysics.value = 0.5 + Math.sin(this.frame / 100) / 4.5
      })
      return
    }

    Object.keys(this.agitators).forEach((key) => {

      const agitatorPhysics = this.agitators[key]

      const friction = agitatorPhysics.vel / 7.75

      agitatorPhysics.vel = agitatorPhysics.vel + agitatorPhysics.accel * 2 - friction

      agitatorPhysics.value = clamp(agitatorPhysics.value + agitatorPhysics.vel, 0.0, MAX_AGITATION_VALUE)

      if (agitatorPhysics.value > 0) {
        agitatorPhysics.value -= 0.01
      }

      agitatorPhysics.accel /= 3

      if (agitatorPhysics.value > MAX_AGITATION_VALUE - 0.1) {
        agitatorPhysics.noiseOffset += 0.013
      }

    })
  }

  getNoiseOffset(key) {
    return this.agitators[key].noiseOffset
  }

  getValue(key) {
    return this.agitators[key].value
  }

}

export default function useKeyboardAgitator({ ignoreInput }) {
  const agitationManager = useMemo(() => new AgitationManager({ ignoreInput }), [ignoreInput])
  const { handleKeyboardInput } = useGlobalState()
  const handleInput = useCallback(({ keyCode }) => {

    if (ignoreInput) return

    handleKeyboardInput()
    if (leftHandKeys.includes(keyCode)) {
      agitationManager.agitate('left')
      return
    }

    if (rightHandKeys.includes(keyCode)) {
      agitationManager.agitate('right')
      return
    }

    agitationManager.agitate('left')
    agitationManager.agitate('right')
  }, [agitationManager, handleKeyboardInput])

  useEffect(() => {
    window.addEventListener('keydown', handleInput)

    return () => {
      window.removeEventListener('keydown', handleInput)
    }
  }, [handleInput])

  return agitationManager
}
