import React, { useRef, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import isSafari from 'is-safari'
import useGlobalState from '../../hooks/useGlobalState'
import fetchVTT from '../../utils/fetchVTT'

const PROGRESS_INTERVAL = 100

function Audio({
  id,
  seekTime = 0,
  isPlaying,
  onStop,
  src,
  loop,
  volume,
  onProgress,
  fadeInOut,
  finishAt,
  children,
  audioRef,
  volumeAdjust,
  onSubsLoad,
}) {
  const elementRef = useRef(null)
  const progressIntervalRef = useRef(null)
  const [fadeMultiplier, setFadeMultiplier] = useState(1)

  // Add way to access the audio elements in the console
  useEffect(() => {
    const debugname = id.replace('-', '')
    window[debugname] = elementRef.current
    if (elementRef.current) {
      elementRef.current.load()
    }
  }, [elementRef.current])

  // Handle fading in and out
  useEffect(() => {
    if (isPlaying) {
      progressIntervalRef.current = setInterval(() => {
        if (onProgress) onProgress({ id, element: elementRef.current })

        const { duration } = elementRef.current

        const currentTime = elementRef.current.currentTime

        // Determine the time left for the current audio. If a specific
        // finishAt prop is set, use that value as the duration. This only
        // affects the bg music.
        const timeLeft = finishAt
          ? finishAt - currentTime
          : duration - currentTime

        // It should fade if fadeInOut is set. This only affects the bg music.
        // In addition, the timeLeft or the currentTime should be less than
        // the fadeInOut threshold

        const shouldFade =
          fadeInOut &&
          (currentTime < fadeInOut ||
            timeLeft < fadeInOut ||
            fadeMultiplier !== 1)

        if (currentTime >= finishAt) {
          // This block never runs. finishAt is only set on the bg music, and it
          // has loop set to true. The narration does not have finishAt set so
          // the outer block is not ran in the first place
          if (!loop && onStop && elementRef.current) {
            elementRef.current.pause()
            onStop()
            return
          }

          // Return back to 0 if the currentTime is over the manually set
          // duration of the audio
          elementRef.current.currentTime = 0
          return
        }

        // Fades the audio
        if (shouldFade) {
          if (timeLeft <= fadeInOut) {
            setFadeMultiplier(timeLeft / fadeInOut)
            return
          }

          if (currentTime <= fadeInOut) {
            setFadeMultiplier(currentTime / fadeInOut)
            return
          }

          if (currentTime > fadeInOut && timeLeft > fadeInOut) {
            setFadeMultiplier(1)
          }
        }
      }, PROGRESS_INTERVAL)
    }

    return () => {
      clearInterval(progressIntervalRef.current)
    }
  }, [
    isPlaying,
    onProgress,
    id,
    fadeInOut,
    finishAt,
    loop,
    fadeMultiplier,
    onStop,
  ])

  // Handle when seekTime is modified
  useEffect(() => {
    if (!elementRef.current) return

    // seekTime is updated everytime a new scene has started. We pause the
    // current audio first, then move the currentTime to prevent a chrome bug
    // where seeking the currentTime while its playing causes it to go to 0,
    // instead of seekTime.
    //
    // The Narration component handles playing the narration again after 50ms,
    // since this component is also mounted as the scene is loaded.
    //
    // The global state is also set to paused here. The logic to handle that is
    // in `NarrationUI`.
    elementRef.current.pause()
    elementRef.current.currentTime = seekTime
  }, [seekTime])

  useEffect(() => {
    if (!elementRef.current) return

    elementRef.current.volume = volume * fadeMultiplier * volumeAdjust
  }, [volume, fadeMultiplier, volumeAdjust])

  // This matches the state of the global state to the internal state of the
  // audio element
  useEffect(() => {
    async function applyPlaybackState() {
      if (!elementRef.current) return

      const method = isPlaying ? 'play' : 'pause'

      try {
        await elementRef.current[method]()
      } catch (e) {
        console.error(`Unable to play audio ${id}: ${e.message}`)
      }
    }

    applyPlaybackState()
  }, [isPlaying, id])

  return (
    <audio src={src} ref={elementRef} loop={loop} preload="metadata">
      <source src={src} type="audio/mp3" />
      {children}
    </audio>
  )
}

export default function ConnectedAudio({
  src,
  id,
  finishAt,
  fadeInOut,
  loop,
  subs,
  onCuechange,
  volumeAdjust,
  onSubsLoad,
  chapters,
  onChaptersLoad,
  startAt,
}) {
  const { globalVolume, registerAudio, audio } = useGlobalState()

  useEffect(() => {
    registerAudio({ id })
  }, [id, registerAudio])

  const subsTrackRef = useRef(null)
  const chaptersTrackRef = useRef(null)

  const [eventListenersKey, setEventListenersKey] = useState(false)

  useEffect(() => {
    if (onCuechange && subsTrackRef?.current) {
      const subsRef = subsTrackRef.current
      subsRef.addEventListener('cuechange', onCuechange)

      return () => subsRef.removeEventListener('cuechange', onCuechange)
    }
  }, [subsTrackRef.current, onCuechange, eventListenersKey])

  useEffect(() => {
    const chaptersRef = chaptersTrackRef?.current

    if (onCuechange && chaptersRef) {
      chaptersRef.mode = 'showing'
      chaptersRef.track.mode = 'showing'
      chaptersRef.addEventListener('cuechange', onCuechange)
    }

    return () => {
      chaptersRef?.removeEventListener('cuechange', onCuechange)
    }
  }, [chaptersTrackRef.current, onCuechange, eventListenersKey])

  useEffect(() => {
    if (!subsTrackRef?.current) return

    subsTrackRef.current.mode = 'showing'
    subsTrackRef.current.track.mode = 'showing'
    // see here:
    // https://stackoverflow.com/questions/25151814/video-track-element-only-triggers-load-event-on-google-chrome

    const target = subsTrackRef.current

    if (target.readyState >= 2 && target?.track?.cues?.length && !isSafari) {
      // UA sniffing is bad juju, but atm I can't find another workaround
      onSubsLoad({ target })
      return
    }
  }, [onSubsLoad, subsTrackRef?.current?.readyState, subs, chapters])

  useEffect(() => {
    if (!isSafari) return

    async function loadSafariSubs() {
      if (subs && chapters) {
        const safariSubs = await fetchVTT({ url: subs })
        subsTrackRef.current.track.mode = 'showing'

        safariSubs.forEach((cue) => {
          subsTrackRef.current?.track?.addCue(cue)
        })
        onSubsLoad({ target: subsTrackRef.current })
        setEventListenersKey((value) => !value)
      }
    }

    loadSafariSubs()
  }, [subs, chapters, onSubsLoad])

  return (
    <Audio
      id={id}
      src={src}
      isPlaying={audio[id]?.isPlaying}
      seekTime={startAt}
      finishAt={finishAt}
      fadeInOut={fadeInOut}
      volume={globalVolume}
      loop={loop}
      volumeAdjust={volumeAdjust}
    >
      {subs && (
        <track
          ref={subsTrackRef}
          default
          kind="subtitles"
          src={subs}
          srcLang="en"
          id="subs"
        />
      )}
      {chapters && (
        <track
          id="chapters"
          ref={chaptersTrackRef}
          kind="metadata"
          src={chapters}
        />
      )}
    </Audio>
  )
}

Audio.propTypes = {
  id: propTypes.string,
  seekTime: propTypes.number,
  isPlaying: propTypes.bool,
  onStop: propTypes.func,
  src: propTypes.string,
  loop: propTypes.bool,
  volume: propTypes.number,
  onProgress: propTypes.func,
  onCuechange: propTypes.func,
  fadeInOut: propTypes.number,
  finishAt: propTypes.number,
  startAt: propTypes.number,
  subs: propTypes.string,
  volumeAdjust: propTypes.number,
}

Audio.defaultProps = {
  seekTime: 0,
  volume: 1,
  volumeAdjust: 1,
}
