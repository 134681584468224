import React from 'react'
import styled from 'styled-components'
import useUpdateCurrentScene from '../../hooks/useUpdateCurrentScene'
import Narration from '../Narration'
import { createFullScreenFlex } from '../StyleUtils'

const SceneWrapper = createFullScreenFlex({ elementType: 'section' })

const FlexColumnSceneWrapper = styled(SceneWrapper)`
  flex-direction: column;
`

export default function Outro({ path }) {
  useUpdateCurrentScene()

  return (
    <FlexColumnSceneWrapper>
      <Narration />
    </FlexColumnSceneWrapper>
  )
}