import React, { useEffect } from 'react'
import Head from '../components/Head'
import { defaultHeadLinks } from '../../siteConfig'

import MobileView from '../components/MobileView'
import {
  HiddenOnDesktop,
  HiddenOnMobile,
} from '../components/StyleUtils'
import DesktopView from '../components/DesktopView'
import drawFiglet from '../utils/figlet'

function IndexPage() {

  useEffect(drawFiglet, [])

  return (
    <>
      <Head links={defaultHeadLinks} />
      <HiddenOnDesktop>
        <MobileView />
      </HiddenOnDesktop>
      <HiddenOnMobile>
        <DesktopView />
      </HiddenOnMobile>
    </>
  )
}

export default IndexPage
