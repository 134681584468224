module.exports = {
  siteName: 'Keyboard Yoga | A ZSA Project',
  siteUrl: 'https://keyboardyoga.space',
  theming: {
    colorPrimary: '#170630',
    colorSecondary: '#0E3C67',
    colorTertiary: '#008CBA',
    colorWhite: '#F3F3F3',
    fontFamilyPrimary: 'Montserrat',
    fontWeightPrimary: 700,
    fontFamilySecondary: 'Montserrat',
    fontWeightSecondary: 400,
    pageTransitionDuration: 1,
    defaultTransitionDuration: 1,
  },
  breakpoints: {
    mobile: 440
  },
  siteMetadata: {
    title: 'Keyboard Yoga',
    titleTemplate: '%s · The Real Hero',
    description:
      'A ZSA Project.',
    url: 'https://keyboardyoga.space',
    image: '/snape.jpg', // TODO get the correct image
    twitterUsername: '@ErgoDoxEZ',
  },
  defaultHeadLinks: []
}

