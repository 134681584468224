import useLazyAssets, { ASSET_TYPE_IMAGE } from './useLazyAssets'
import blobsMask from '../images/blobs-mask.png'

const assets = [
  {
    name: 'uBackground',
    type: ASSET_TYPE_IMAGE,
    url: blobsMask
  }
]

export default function useBlobsSeceneAssets() {
  return useLazyAssets({ assets })
}
