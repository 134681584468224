import React, { forwardRef, memo, useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { theming } from '../../../siteConfig'
import { createFullScreenFlex } from '../StyleUtils'
import Transitioned from '../Transitioned'

const { pageTransitionDuration: duration } = theming

const FullScreenFlex = createFullScreenFlex()

const wrapper = forwardRef((props, ref) => <FullScreenFlex ref={ref} {...props} />)

function Scene({
  children,
  path,
  delay,
}) {
  const [visible, setVisible] = useState(false)
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (delay) {
      timeoutRef.current = setTimeout(() => {
        setVisible(true)
      }, delay * 1000)
      return () => clearTimeout(timeoutRef.current)
    }

    setVisible(true)
  }, [])

  if (!visible) return null


  return (
    <Transitioned
      key={path}
      duration={duration}
      wrapper={wrapper}
    >
      {children}
    </Transitioned>
  )
}

export default memo(Scene)

Scene.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
  path: propTypes.string,
  delay: propTypes.number,
}