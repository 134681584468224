const moduleCache = {}

function loadParser() {
  return new Promise(async (resolve, reject) => {
    if (moduleCache.parse) {
      resolve(moduleCache.parse)
      return
    }

    const { parse } = await import('@plussub/srt-vtt-parser')
    resolve(parse)
  })

}

export default function fetchVTT({ url }) {
  return new Promise(async (resolve, reject) => {
    try {
      const parse = await loadParser()
      const req = await fetch(url)
      const subsText = await req.text()
      
      const { entries } = parse(subsText)

      const cues = entries.map(({ from, to, text, id }) => {
        const start = from / 1000
        const end = to / 1000
        const cue = new VTTCue(start, end, text)
        cue.id = id
        return cue
      })

      resolve(cues)
    } catch(e) {
      console.error(e)
      reject(e)
    }
  })
}
