const figlet = `
%c                                   o8o
%c                                   \`"'
%c  oooooooo  .oooo.o  .oooo.       oooo   .ooooo.
%c d'""7d8P  d88(  "8 \`P  )88b      \`888  d88' \`88b
%c   .d8P'   \`"Y88b.   .oP"888       888  888   888
%c .d8P'  .P o.  )88b d8(  888  .o.  888  888   888
%cd8888888P  8""888P' \`Y888""8o Y8P o888o \`Y8bod8P'
%c===================================================
%c        next-generation ergonomic keyboards
%c===================================================
`

const HUE_STEP = -15
const HUE_OFFSET = 60

const styles = new Array(10)
  .fill(0)
  .map((_,i) => `color: hsl(${(i * HUE_STEP) + HUE_OFFSET}deg, 100%, 70%);`)
  
export default function drawFiglet() {
  console.info(figlet, ...styles)
}

