export const ACTION_RESET_STATE = 'ACTION_RESET_STATE'
export const ACTION_SELECT_GENDER = 'ACTION_SELECT_GENDER'
export const ACTION_SET_GLOBAL_VOLUME = 'ACTION_SET_GLOBAL_VOLUME'
export const ACTION_PLAY_AUDIO = 'ACTION_PLAY_AUDIO'
export const ACTION_STOP_AUDIO = 'ACTION_STOP_AUDIO'
export const ACTION_REGISTER_AUDIO = 'ACTION_REGISTER_AUDIO'
export const ACTION_SET_ACTIVE_SUBTITLES = 'ACTION_SET_ACTIVE_SUBTITLES'
export const ACTION_REGISTER_SUBTITLES = 'ACTION_REGISTER_SUBTITLES'
export const ACTION_SET_ACTIVE_CHAPTER = 'ACTION_SET_ACTIVE_CHAPTER'
export const ACTION_CHAPTER_END_EVENT = 'ACTION_CHAPTER_END_EVENT'
export const ACTION_SHOW_NARRATION_UI = 'ACTION_SHOW_NARRATION_UI'
export const ACTION_HIDE_NARRATION_UI = 'ACTION_HIDE_NARRATION_UI'
export const ACTION_REGISTER_KEYBOARD_INPUT = 'ACTION_REGISTER_KEYBOARD_INPUT'
export const ACTION_CLEAR_KEYBOARD_INPUT = 'ACTION_CLEAR_KEYBOARD_INPUT'
export const ACTION_SET_CURRENT_SCENE = 'ACTION_SET_CURRENT_SCENE'

export const TYPE_GENDER_MALE = 'TYPE_GENDER_MALE'
export const TYPE_GENDER_FEMALE = 'TYPE_GENDER_FEMALE'