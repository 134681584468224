import React, { memo } from 'react'
import propTypes from 'prop-types'
import { motion } from 'framer-motion'
import { theming } from '../../../siteConfig'

const { defaultTransitionDuration } = theming


// GOTCHA: when passing a custom wrapper you need to use
// forward ref on the element see below:
// https://www.framer.com/docs/component/#custom-components

function Transitioned({
  children,
  key,
  duration,
  element,
  wrapper,
  delay,
}) {

  const pageMotion = {
    initial: { opacity: 0, filter: 'blur(20px)' },
    animate: { opacity: 1, delay, filter: 'blur(0px)', transition: { duration } },
    exit: { opacity: 0, filter: 'blur(20px)', transition: { duration } }
  }

  const Component = wrapper 
    ? motion(wrapper)
    : motion[element]

  return (
    <Component
      key={key + element}
      variants={pageMotion}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </Component>
  )
}

export default memo(Transitioned)

Transitioned.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
    propTypes.element,
  ]),
  key: propTypes.string,
  duration: propTypes.number,
  wrapper: propTypes.oneOfType([
    propTypes.node,
    propTypes.element,
    propTypes.shape({  current: propTypes.instanceOf(React.Component) })
  ]),
  element: propTypes.string,    
  delay: propTypes.number,
}

Transitioned.defaultProps = {
  duration: defaultTransitionDuration,
  element: 'div',
  delay: 1
}
