import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import propTypes from 'prop-types'
import useGlobalState, { types } from '../../hooks/useGlobalState'
import Button from '../Button'
import { createFullScreenFlex, Typography } from '../StyleUtils'
import useUpdateCurrentScene from '../../hooks/useUpdateCurrentScene'

const {
  TYPE_GENDER_MALE,
  TYPE_GENDER_FEMALE,
} = types

const SceneWrapper = createFullScreenFlex({ elementType: 'section' })

const SelectionButtons = styled.div`
  display: block;
  display: flex;
  justify-content: center;
  width: 100%;
`

const PositionedTypography = styled(Typography)`
  top: 50%;
  transform: translateY(calc(-50% - 6.5rem));
  position: absolute;
  /*
    in the design the buttons are positioned in the vertical center
    with the text above.
    This means that we have do some inventive positioning
    top 50% to move the top of the element to the center
    translateY -50% to center the element verticaly
    translateY -1.5rem to position it above the buttons
    translateY -5.0rem to position inline with the design...
  */
`

const RowItem = styled.div`
  margin: 0 0.75rem;
`

export default function SelectNarration({ path, nextScene }) {
  useUpdateCurrentScene()

  const {
    selectGender,
  } = useGlobalState()

  return (
    <SceneWrapper>
      <PositionedTypography>Put on some headphones.</PositionedTypography>
      <SelectionButtons>
        <RowItem>
          <Link to="/intro">
            <Button onClick={() => selectGender(TYPE_GENDER_MALE)}>Male Narration</Button>
          </Link>
        </RowItem>
        <RowItem>
          <Link to="/intro">
            <Button onClick={() => selectGender(TYPE_GENDER_FEMALE)}>Female Narration</Button>
          </Link>
        </RowItem>
      </SelectionButtons>
    </SceneWrapper>
  )
}

SelectNarration.propTypes = {
  path: propTypes.string,
  nextScene: propTypes.string,
}