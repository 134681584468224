import React from 'react'
import styled from 'styled-components'
import useBlobsSeceneAssets from '../../hooks/useBlobsSceneAssets'
import Blobs from '../Blobs'
import { createFullScreenFlex } from '../StyleUtils'
import Transitioned from '../Transitioned'

const SceneWrapper = createFullScreenFlex({ elementType: 'section' })

const FlexColumnSceneWrapper = styled(SceneWrapper)`
  flex-direction: column;
`

export default function BlobsScene({ path }) {
  const { assets, allLoaded } = useBlobsSeceneAssets()

  if (!allLoaded) return null
  return (
    <FlexColumnSceneWrapper>
      <Transitioned>
          <Blobs assets={assets} />
      </Transitioned>
    </FlexColumnSceneWrapper>
  )
}