import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useGlobalState from './useGlobalState'

export default function useUpdateCurrentScene() {

  const location = useLocation()

  const {
    setCurrentScene,
  } = useGlobalState()

  useEffect(() => {
    setCurrentScene(location.pathname)
  }, [location.pathname])

}