import React, { useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useGlobalState from '../../hooks/useGlobalState'
import Button from '../Button'

function SimpleRedirect() {
  const navigate = useNavigate()
  const location = useLocation()
  const { pauseNarration } = useGlobalState()

  useEffect(() => {
    // Check if the location.pathname is not `/outro` to prevent an infinite
    // loop. This happens when the `/outro` timestamp set in `BackgroundAudio`
    // is at the same location as `EXP_NAV_OUTRO`. This causes SimpleRedirect to
    // mount again, instead of the next narration to play.

    if (location.pathname !== '/outro') {
      pauseNarration()

      setTimeout(() => {
        navigate('/outro')
      }, 25)
    }
  }, [location.pathname, navigate, pauseNarration])

  return null
}

const LinkWithMargin = styled(Link)`
  margin-top: 5rem;
`

// This function is where the scene transition happens. The VTT schedules when
// these components are rendered, and these components handle showing the UI, or
// just redirecting the compoenents directly.
//
// Each transition pauses the narration to prevent issues where the global state
// is desynced from the audio, since each scene transition also pauses the
// audio.

export default function NarrationUI({ id, onCTAClick }) {
  const { resetState, pauseNarration } = useGlobalState()

  if (id === 'INTRO_LAST') {
    return (
      <LinkWithMargin to="/experience">
        <Button onClick={() => pauseNarration()}>I&apos;m ready</Button>
      </LinkWithMargin>
    )
  }

  if (id === 'EXP_NAV_OUTRO') {
    return <SimpleRedirect />
  }

  if (id === 'OUTRO_LAST') {
    return (
      <LinkWithMargin to="/select">
        <Button onClick={() => resetState()}>Restart</Button>
      </LinkWithMargin>
    )
  }

  if (id === 'SPLASH_BEGIN') {
    return (
      <Link to="/select">
        <Button onClick={onCTAClick}>Click to begin</Button>
      </Link>
    )
  }

  return null
}
