export default function isWebGLCapable() {
  try {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('webgl')

    return Boolean(ctx)
  } catch(e) {
    console.error(e)
    return false
  }
}