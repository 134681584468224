import React from 'react'
import styled from 'styled-components'
import zsaLogo from '../../images/ZSA-logo.svg'

const FooterWrapper = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.75rem 4.56rem;
  text-align: center;
`

export default function Footer() {
  return (
    <FooterWrapper>
      <a
        href="https://zsa.io"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={zsaLogo}
          alt=""
        />
      </a>
    </FooterWrapper>
  )
}