import * as types from './types'
import useGlobalState, {
  withGlobalStateManagementContext,
} from './useGlobalState'
import useGlobalStateReducer from './useGlobalStateReducer'

export default useGlobalState

export {
  types,
  withGlobalStateManagementContext,
  useGlobalStateReducer
}