import React, { memo } from 'react'
import { BrowserRouter } from 'react-router-dom'
import compose from 'lodash/fp/compose'
import Background from '../Background'
import Nav from '../Nav'
import Footer from '../Footer'
import { createFullScreenFlex } from '../StyleUtils'
import AnimatedRouting from '../AnimatedRouting'
import { Experience, Intro, Outro, SelectNarration, SplashPage } from '../Scenes'
import Blobs from '../Scenes/Blobs'
import ClientOnly from '../ClientOnly'
import { withGlobalStateManagementContext } from '../../hooks/useGlobalState'

const Main = createFullScreenFlex({ elementType: 'main' })

const routes = [
  {
    path: '/',
    Component: SplashPage,
  },
  {
    path: '/select',
    Component: SelectNarration,
  },
  {
    path: '/intro',
    Component: Intro,
  },
  {
    path: '/experience',
    Component: Experience,
    delay: 2,
  },
  {
    path: '/outro',
    Component: Outro,
  },
  {
    path: '/blobs-test-scene',
    Component: Blobs,
  },
]

function DesktopView() {
  return (
    <>
      <Background />
      <Nav />
      <Main className='font-family-primary'>
        <ClientOnly>
          <BrowserRouter>
            <AnimatedRouting routes={routes}/>
          </BrowserRouter>
        </ClientOnly>
      </Main>
      <Footer />
    </>
  )
}

const enhance = compose(
  withGlobalStateManagementContext,
  memo,
)

export default enhance(DesktopView)