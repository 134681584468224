import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ACTION_SELECT_GENDER,
  ACTION_SET_GLOBAL_VOLUME,
  ACTION_PLAY_AUDIO,
  ACTION_STOP_AUDIO,
  ACTION_REGISTER_AUDIO,
  ACTION_SET_ACTIVE_SUBTITLES,
  ACTION_REGISTER_SUBTITLES,
  ACTION_SET_ACTIVE_CHAPTER,
  ACTION_SHOW_NARRATION_UI,
  ACTION_HIDE_NARRATION_UI,
  ACTION_RESET_STATE,
  ACTION_REGISTER_KEYBOARD_INPUT,
  ACTION_CLEAR_KEYBOARD_INPUT,
  ACTION_SET_CURRENT_SCENE,
} from './types'

const DEFAULT_CHAPTER_AWAIT = 1000
const DEFAULT_KEYBOARD_INPUT_AWAIT = 1000
const VTT_MARKER_TIME = 'TIME'

export default function useGlobalStateActions({ dispatch, getState }) {
  const selectGender = useCallback((gender) => {
    dispatch({
      type: ACTION_SELECT_GENDER,
      payload: gender,
    })
  }, [])

  const playBgMusic = useCallback(() => {
    dispatch({
      type: ACTION_PLAY_AUDIO,
      payload: 'bg-music',
    })
  }, [])

  // TODO make this DRYer
  // `createAction` or useActions
  const setGlobalVolume = useCallback((volume) => {
    dispatch({
      type: ACTION_SET_GLOBAL_VOLUME,
      payload: volume,
    })
  }, [])

  const registerAudio = useCallback(({ id }) => {
    dispatch({
      type: ACTION_REGISTER_AUDIO,
      payload: id,
    })
  }, [])

  const registerSubtitles = useCallback(({ subs }) => {
    dispatch({
      type: ACTION_REGISTER_SUBTITLES,
      payload: subs,
    })
  }, [])

  const setActiveSubtitles = useCallback(({ id }) => {
    dispatch({
      type: ACTION_SET_ACTIVE_SUBTITLES,
      payload: id,
    })
  }, [])

  const setActiveChapter = useCallback(({ id }) => {
    dispatch({
      type: ACTION_SET_ACTIVE_CHAPTER,
      payload: id,
    })
  }, [])

  const playNarration = useCallback(() => {
    dispatch({
      type: ACTION_PLAY_AUDIO,
      payload: 'narration',
    })
  }, [])

  const pauseNarration = useCallback(() => {
    dispatch({
      type: ACTION_STOP_AUDIO,
      payload: 'narration',
    })
  }, [])

  const displayNarrationUI = useCallback(({ id }) => {
    dispatch({
      type: ACTION_SHOW_NARRATION_UI,
      payload: id,
    })
  }, [])

  const hideNarrationUI = useCallback(() => {
    dispatch({
      type: ACTION_HIDE_NARRATION_UI,
    })
  }, [])

  const resetState = useCallback(() => {
    dispatch({
      type: ACTION_RESET_STATE,
    })
  }, [])

  const setCurrentScene = useCallback((scene) => {
    dispatch({
      type: ACTION_SET_CURRENT_SCENE,
      payload: scene,
    })
  }, [])

  const chapterEndTimeoutRef = useRef(null)
  const keyboardInputTimeoutRef = useRef(null)

  const startNarrationDebounced = useCallback(
    (timeout = DEFAULT_CHAPTER_AWAIT) => {
      // If there's a timeout already in motion, we don't do anything
      if (chapterEndTimeoutRef.current) return

      // We set a timeout to play the next line in the narration, if the
      // keyboard is active.

      // This also means that we only pause between chapters
      // if there's no keyboard input
      chapterEndTimeoutRef.current = setTimeout(() => {
        const { keyboardTouched } = getState()

        if (keyboardTouched) playNarration()

        clearTimeout(chapterEndTimeoutRef.current)
        chapterEndTimeoutRef.current = null
      }, timeout)
    },
    [getState, playNarration],
  )

  // Whenever a chapter ends we don't go to the next line immediately. We pause
  // the audio, and wait a few seconds.
  const handleChapterEnd = useCallback(
    ({ chapterEndEventType, chapterEndEventValue = DEFAULT_CHAPTER_AWAIT }) => {
      pauseNarration()

      // Clear any previous timeouts. Chapter end timeouts can also be created
      // by keyboard inputs so this just cleans that up
      if (chapterEndTimeoutRef.current || chapterEndTimeoutRef.current === 0) {
        clearTimeout(chapterEndTimeoutRef.current)
      }

      // If the event type is `TIME` , the narration is started. This also means
      // that any other event type, such as `UI` is ignored.
      if (chapterEndEventType === VTT_MARKER_TIME) {
        startNarrationDebounced(chapterEndEventValue)
      }
    },
    [pauseNarration, startNarrationDebounced],
  )

  const handleKeyboardInput = useCallback(() => {
    const { audio } = getState()

    const narrationActive = audio?.narration?.isPlaying

    // If the narration was paused, such as when the chapter has ended, we start
    // the narration.
    if (!narrationActive) {
      startNarrationDebounced()
    }

    dispatch({
      type: ACTION_REGISTER_KEYBOARD_INPUT,
    })

    // Everytime we type, we clear the timeout to clear the keyboard input. If
    // more than 1 second passes that the user doesn't type anything, we clear
    // the keyboard input. This disables the next narration cue
    if (keyboardInputTimeoutRef.current) {
      clearTimeout(keyboardInputTimeoutRef.current)
    }

    keyboardInputTimeoutRef.current = setTimeout(() => {
      dispatch({
        type: ACTION_CLEAR_KEYBOARD_INPUT,
      })
    }, DEFAULT_KEYBOARD_INPUT_AWAIT)
  }, [])

  return {
    selectGender,
    setGlobalVolume,
    registerAudio,
    playNarration,
    pauseNarration,
    playBgMusic,
    registerSubtitles,
    setActiveSubtitles,
    setActiveChapter,
    handleChapterEnd,
    handleKeyboardInput,
    displayNarrationUI,
    hideNarrationUI,
    resetState,
    setCurrentScene,
  }
}
