/* eslint-disable import/no-webpack-loader-syntax */

import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import bgLeftImg from '../../images/BG-left.png'
import bgRightImg from '../../images/BG-right.png'
import Planet from '../Planet'
import BackgroundAudio from './BackgroundAudio'

const BackgroundBase = styled.div`
  top: 0;
  bottom: 0;
  width: 50vw;
  position: absolute;
  background-size: cover;
  z-index: -1;
`

const BackgroundLeft = styled(BackgroundBase)`
  background-image: url(${bgLeftImg});
  background-position: right;
  left: 0;
  max-width: 788px;
`

const BackgroundRight = styled(BackgroundBase)`
  background-image: url(${bgRightImg});
  background-position: left;
  right: 0;
  max-width: 939px;
`


export default function Background() {
  return (
    <>
      <BackgroundAudio />
      <BackgroundLeft />
      <BackgroundRight />
      <Planet
        type="comet"
        position={{
          x: '10vw',
          y: '50vh'
        }}
      />
      <Planet
        type="ringed-1"
        animationOffset={0.2}
        position={{
          x: '19vw',
          y: '25vh'
        }}
      />
      <Planet
        type="unringed"
        animationOffset={0.4}
        position={{
          x: '25vw',
          y: '70vh'
        }}
        scale={0.6}
      />
      <Planet
        type="comet"
        position={{
          x: '79.5vw',
          y: '17vh'
        }}
        scale={0.6}
        animationOffset={0.6}
      />
      <Planet
        type="comet"
        position={{
          x: '80vw',
          y: '22vh'
        }}
        animationOffset={0.8}
      />
      <Planet
        type="unringed"
        animationOffset={1.0}
        position={{
          x: '90vw',
          y: '55vh'
        }}
      />
      <Planet
        type="ringed-2"
        position={{
          x: '65vw',
          y: '75vh'
        }}
        animationOffset={1.2}
      />
    </>
  )
}