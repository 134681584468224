/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useMemo,
} from 'react'
import useGlobalStateActions from './useGlobalStateActions'
import useGlobalStateReducer from './useGlobalStateReducer'

const GlobalStateManagementContext = createContext()

function useGlobalStateManagement() {
  const [state, dispatch, getState] = useGlobalStateReducer()

  const actions = useGlobalStateActions({ dispatch, getState })

  // this is to prevent unnecessary rerendering
  const contextValue = useMemo(() => ({
    ...state,
    ...actions,
  }), [
    state.stateKey,
    ...Object.values(actions),
  ])

  return contextValue
}

export function withGlobalStateManagementContext(WrappedComponent) {
  return function ComponentWithGlobalStateManagement(props) {
    const globalStateManagement = useGlobalStateManagement()
    return (
      <GlobalStateManagementContext.Provider value={globalStateManagement}>
        <WrappedComponent {...props} />
      </GlobalStateManagementContext.Provider>
    )
  }
}

export default function useGlobalState() {
  return useContext(GlobalStateManagementContext)
}