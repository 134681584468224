import React from 'react'
import propTypes from 'prop-types'
import styled, { css } from 'styled-components'

const TypographyBase = styled.p`
  text-align: ${({ align }) => align};
  text-transform: ${({ caps }) => caps ? 'uppercase' : 'none'};
  margin-bottom: ${({ useMargin }) => useMargin ? '1em' : 0};
  pointer-events: ${({ pointerEventsNone }) => pointerEventsNone ? 'none' : 'inherit'}
`

const Large = styled(TypographyBase)`
  font-size: 1.95rem;
  line-height: 3rem;
`

const Small = styled(TypographyBase)`
  font-size: 1.125rem;
  line-height: 2rem;
`

function getFontFamilyClass({ variant }) {
  if (variant === 'large') {
    return 'font-family-primary'
  }

  return 'font-family-secondary'
}

function Typography({
  variant,
  className,
  align,
  overrides,
  ...props
}) {
  const fontFamilyClass = getFontFamilyClass({ variant })

  const elementClassName = [
    fontFamilyClass,
    className,
  ].join(' ')

  if (variant === 'large') {

    return (
      <Large
        align={align}
        className={elementClassName}
        style={overrides}
        {...props}
      />
    )
  }

  return (
    <Small
      className={elementClassName}
      style={overrides}
      align={align}
      {...props}
    />
  )


}

Typography.propTypes = {
  align: propTypes.oneOf([
    'center',
    'left',
    'right',
  ]),
  className: propTypes.string,
  caps: propTypes.bool,
  useMargin: propTypes.bool,
  overrides: propTypes.object,
  variant: propTypes.oneOf([
    'large',
    'small',
  ]),
  pointerEventsNone: propTypes.bool,
}

Typography.defaultProps = {
  align: 'center',
  className: '',
  overrides: {},
  variant: 'large',
  caps: false,
  useMargin: false,
}

export default Typography
