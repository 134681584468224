import styled from 'styled-components'

const createFullScreenFlex = ({
  elementType = 'div'
} = {}) => styled[elementType]`
  display: flex;
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`

export default createFullScreenFlex
