import { useCallback, useMemo, useReducer, useRef } from 'react'


export default function useEnhancedReducer(reducer, initState, initializer, middlewares = []) {
  const lastState = useRef(initState)

  const getState = useCallback(() => lastState.current, [])

  const enhancedReducer = useRef((state, action) => lastState.current = reducer(
    state,
    action
  )).current // to prevent reducer called twice, per: https://github.com/facebook/react/issues/16295

  const [state, dispatch] = useReducer(
      enhancedReducer,
      initState,
      initializer
    )

  const middlewaresRef = useRef(middlewares)

  const enhancedDispatch = useMemo(()=>middlewaresRef.current.reduceRight(
    (acc, mdw) => action => mdw(state)(getState)(acc)(action),
    dispatch
  ), []) // value of middlewares is memoized in the first time of calling useEnhancedReducer(...)

  return [state, enhancedDispatch, getState]
}