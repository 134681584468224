/* eslint-disable import/no-webpack-loader-syntax */

import React, { useCallback } from 'react'
import Audio from '../Audio'
import useGlobalState, { types } from '../../hooks/useGlobalState'
import femaleSubsVTT from 'file-loader!../../assets/narration-female.mp3.vtt'
import maleSubsVTT from 'file-loader!../../assets/narration-male.mp3.vtt'
import femaleChaptersVTT from 'file-loader!../../assets/markers-female.vtt'
import maleChaptersVTT from 'file-loader!../../assets/markers-male.vtt'
import isSafari from 'is-safari'

const { TYPE_GENDER_MALE, TYPE_GENDER_FEMALE } = types

const audioMap = {
  [TYPE_GENDER_MALE]: {
    audioSrc: 'https://assets.keyboardyoga.space/narration-male.mp3',
    subsSrc: maleSubsVTT,
    chaptersSrc: maleChaptersVTT,
    startAt: {
      '/intro': 0,
      '/experience': 49,
      '/outro': 319.2,
    },
  },
  [TYPE_GENDER_FEMALE]: {
    audioSrc: 'https://assets.keyboardyoga.space/narration-female.mp3',
    subsSrc: femaleSubsVTT,
    chaptersSrc: femaleChaptersVTT,
    startAt: {
      '/intro': 0,
      '/experience': 42.9,
      '/outro': 278.6,
    },
  },
}

export default function BackgroundAudio() {
  const {
    selectedGender,
    registerSubtitles,
    setActiveSubtitles,
    setActiveChapter,
    activeChapter,
    handleChapterEnd,
    currentScene,
  } = useGlobalState()

  const handleCueChange = useCallback(
    ({ target }) => {
      const activeCue = target.track.activeCues[0]
      const { id } = activeCue ? activeCue : { id: null }
      if (target.id === 'chapters') {
        if (id !== activeChapter) {
          // I think this happens whenever a chapter ends. ID becomes null
          // because the cue changes from an active chapter to one where there's
          // no set chapter in the VTT
          if (!id) {
            const [, , chapterEndEventType, chapterEndEventValue] =
              activeChapter.split(':')

            // The event value is parsed into an int. This value may be a string, or a
            // number. This just cleans it up and sets them to the appropriate
            // type
            const parsedValue = parseInt(chapterEndEventValue, 10)
            const value = isNaN(parsedValue)
              ? chapterEndEventValue
              : parsedValue

            handleChapterEnd({
              chapterEndEventType,
              chapterEndEventValue: value,
            })
          }

          setActiveChapter({ id })
          return
        }
      }

      if (target.id === 'subs') {
        setActiveSubtitles({ id })
      }
    },
    [setActiveSubtitles, activeChapter, setActiveChapter, handleChapterEnd],
  )

  const handleSubsLoad = useCallback(
    (e) => {
      const subs = Object.values(e.target?.track?.cues).map(({ id, text }) => ({
        id,
        text,
      }))

      registerSubtitles({ subs })
    },
    [registerSubtitles],
  )

  const {
    subsSrc,
    audioSrc,
    chaptersSrc,
    startAt: timestamps,
  } = audioMap[selectedGender] || {}
  const startAt = (timestamps && timestamps[currentScene]) || 0

  return (
    <>
      <Audio
        id="bg-music"
        src="https://assets.keyboardyoga.space/bg-music.mp3"
        finishAt={252}
        fadeInOut={2}
        volumeAdjust={0.1}
        loop
      />
      <Audio
        key={selectedGender}
        id="narration"
        subs={subsSrc}
        chapters={chaptersSrc}
        src={audioSrc}
        onCuechange={handleCueChange}
        onSubsLoad={handleSubsLoad}
        startAt={startAt}
      />
    </>
  )
}
