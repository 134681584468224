import React from 'react'
import styled from 'styled-components'
import { createFullScreenFlex, Typography } from '../StyleUtils'
import keyboardYogaLogo from '../../images/keyboard-yoga-logo.svg'
import Footer from '../Footer'
import mobileBg from '../../images/BG-mobile.png'
import Planet from '../Planet'

const FullScreenFlex = createFullScreenFlex()

const MobileWrapper = styled(FullScreenFlex)`
  background-image: url(${mobileBg});
  background-size: cover;
`

const Content = styled.main`
  padding: 3rem;
`

const Header = styled.header`
  position: absolute;
  top: 0;
  padding: 3rem 1rem;
  text-align: center;
`

const YogaLogo = styled.img`
  width: 100%;
  max-width: 269px;
`

export default function MobileScreen() {
  return (
    <>
      <MobileWrapper>
        <Header>
          <YogaLogo
            alt=""
            src={keyboardYogaLogo}
          />
        </Header>
        <Content>
          <Typography useMargin>
            This typing experience is built for a physical keyboard.
          </Typography>
          <Typography useMargin>Bookmark the site, then come back to it from your computer.</Typography>
          <Typography useMargin>It&apos;s worth it. ❤️</Typography>
        </Content>
        <Footer />
      </MobileWrapper>
      <Planet
        type="ringed-2"
        position={{
          x: '0vw',
          y: '75vh',
        }}
      />
      <Planet
        type="comet"
        position={{
          x: '67vw',
          y: '15vh',
        }}
        scale={0.75}
      />
      <Planet
        type="comet"
        position={{
          x: '69vw',
          y: '20vh',
        }}
      />
    </>
  )
}
