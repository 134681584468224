import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

const Svg = styled.svg`
  stroke: var(--colorWhite);
  stroke-width: 7;
  fill: none;
`

const animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.25 } },
  exit: { opacity: 0, transition: { duration: 0.25 } }
}

export default function VolumeIcon({ volume }) {
  return (
    <Svg
      width="23"
      height="16"
      viewBox="0 0 186.4 126.7"
    >
        <AnimatePresence key="volume-anim">
          {volume > 0.2 && <motion.path key="0.25" d="M119.9,32.6c19.2,30.7,0,61.4,0,61.4" {...animationProps} />}
          {volume > 0.6 && <motion.path key="0.5" d="M143,24.9c30.7,38.4,0,76.8,0,76.8" {...animationProps} />}
          {volume > 0.8 && <motion.path key="0.75" d="M163.3,12.9c39.5,39.5,4.4,100.9,4.4,100.9" {...animationProps} />}
        </AnimatePresence>
        <path d="M41.6,93H15.7c-5.6,0-10.2-4.6-10.2-10.2V43.9c0-5.6,4.6-10.2,10.2-10.2h25.9"/>
        <path d="M88.4,63.2c0,0,0-46.6,0-53.6c0-6.3-7-3.8-9.9-1.9c-2.9,1.9-28.1,18.2-33.3,21.7s-7.2,13.2-7.2,13.2v20.7v0.3 v20.7c0,0,1.9,9.7,7.2,13.2c5.2,3.5,30.4,19.8,33.3,21.7c2.9,1.9,9.9,4.4,9.9-1.9c0-7,0-53.6,0-53.6V63.2z"/>
    </Svg>
  )
}