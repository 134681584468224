import propTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { siteName } from '../../../siteConfig'
import BaseStyles from '../BaseStyles'
import MetaScreenshot from '../../assets/meta-screenshot.png'

const description = 'Many of us spend our days typing, but do we really pause to consider what that feels like in our body? This experience is an opportunity to do just that.'

export default function Head({
  links,
  title,
  children,
}) {
  return (
    <>
      <BaseStyles />
      <Helmet>
        {links?.map(({ rel, href }) => (
          <link
            key={href}
            rel={rel}
            href={href}
          />
        ))}
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keyboardyoga.space" />
        <meta property="og:image" content={MetaScreenshot} />
        <meta property="og:description" content={description} />
        
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={MetaScreenshot} />
        <meta name="twitter:card" content="summary_large_image" />

        {children}
      </Helmet>
    </>
  )
}

// if we were using Typescript, we wouldn't need this
// but for getting up and running quickly with Gatsby
// weĺl just use js for now

Head.propTypes = {
  links: propTypes.arrayOf(
    propTypes.shape({
      rel: propTypes.string,
      href: propTypes.string
    })
  ),
  title: propTypes.string,
  children: propTypes.arrayOf(
    propTypes.element
  )
}

Head.defaultProps = {
  links: [],
  title: siteName
}