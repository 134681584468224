import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import keyboardYogaLogo from '../../images/keyboard-yoga-logo.svg'
import VolumeControl from '../VolumeControl'
import useGlobalState from '../../hooks/useGlobalState'

const StyledNav = styled.nav`
  z-index: 1;
  position: absolute;
  width: 100%;
  text-align: center;
`

const NavContent = styled.div`
  padding: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1500px;
  width: 100%;
`

const NavGroup = styled.div`
  display: flex;
  align-items: center;
`

export default function Nav() {
  const {
    setGlobalVolume,
    globalVolume,
    audio,
  } = useGlobalState()

  const audioIsPlaying = Object.values(audio)
    .some(({ isPlaying }) => isPlaying)

  return (
    <StyledNav>
      <NavContent>
        <img
          alt=""
          src={keyboardYogaLogo}
        />
        <NavGroup>
          <VolumeControl
            onChange={setGlobalVolume}
            volume={globalVolume}
            visible={audioIsPlaying}
          />
          <a
            href="https://zsa.io"
            target="_blank"
            rel="noreferrer"
          >
            <Button caps>
                a zsa project
            </Button>
          </a>
        </NavGroup>
      </NavContent>
    </StyledNav>
  )
}

