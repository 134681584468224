import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from '../StyleUtils'

const StyledButton = styled.button`
  background-color: var(--colorSecondary);
  padding: 0.5rem 2.375rem;
  transition: background-color 0.2s;
  cursor: pointer;
  :hover {
    background-color: var(--colorWhite);
  }

  .button-content {
    transition: color 0.2s;
  }

  :hover .button-content {
    color: var(--colorSecondary);
  }
`

function Button({
  children,
  onClick,
  caps,
}) {
  return (
    <StyledButton onClick={onClick}>
      <Typography
        variant="small"
        caps={caps}
        className="button-content"
      >
        {children}
      </Typography>
    </StyledButton>
  )
}

Button.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
  onClick: propTypes.func,
  caps: propTypes.bool,
}

Button.defaultProps = {
  children: [],
  onClick: () => console.info('No onClick handler passed to Button'),
  caps: false,
}

export default Button
