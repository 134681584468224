import React, { memo, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import propTypes from 'prop-types'
import { Scene } from '.'

function AnimatedRouting ({ routes }) {
  const location = useLocation()

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        {routes.map(({ path, delay, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <Scene path={path} delay={delay}>
                <Component
                  key={path}
                  path={path}
                />
              </Scene>
            }
          />
        ))}
      </Routes>
    </AnimatePresence>
  )
}

export default memo(AnimatedRouting)

AnimatedRouting.propTypes = {
  routes: propTypes.arrayOf(
    propTypes.shape({
      path: propTypes.string.isRequired,
      Component: propTypes.oneOfType([
        propTypes.element,
        propTypes.func,
      ]).isRequired,
      next: propTypes.string,
    })
  )
}