import styled from 'styled-components'
import { breakpoints } from '../../../siteConfig'

// TODO, we should change this to not only
// hide components but avoid rendering them at all
  
const HiddenOnDesktop = styled.div`
  @media (min-width: ${breakpoints.mobile}px) {
    display: none;
  }
`

export default HiddenOnDesktop
