export default function dedupeArrayOfObjects({ array, dedupeKey = 'id' }) {
  return Object.values(array
    .reduce((acc, item) => {
      const key = item[dedupeKey]
      return {
        ...acc,
        [key]: item
      }
    }, {}))
}
