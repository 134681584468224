import { useEffect, useState } from 'react'

export const ASSET_TYPE_IMAGE = 'ASSET_TYPE_IMAGE'

export default function useLazyAssets({ assets = [] }) {
  const [loadedAssets, setAssets] = useState({})

  const handleAssetLoad = ({ name, asset, type }) => {
      setAssets((state) => {
        return {
          ...state,
          [name]: { asset, type },
        }
      })
  }

  useEffect(() => {
    assets.forEach(({ name, url, type }) => {
      if (type === ASSET_TYPE_IMAGE) {
        const img = new Image()

        img.src = url
        img.addEventListener('load', () => handleAssetLoad({ asset: img, name, type }))

        if (img.complete) {
          handleAssetLoad({ asset: img, name, type })
        }
      }
    })
  }, [assets])

  const numLoadedAssets = Object
    .keys(loadedAssets)
    .length

  return {  
    assets: loadedAssets,
    allLoaded: numLoadedAssets === assets.length,
    progress: numLoadedAssets / assets.length
  }
}
