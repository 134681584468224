import { useEffect, useMemo, useRef } from 'react'
import REGL from 'regl'
import isWebGLCapable from '../../utils/isWebGLCapable'
import useKeyboardAgitator from '../useKeyboardAgitator'
import frag from './main.frag.glsl'
import vert from './main.vert.glsl'

export default function useBlobsWebGL({
  assets,
  canvasRef,
  dimensions: [
    CANVAS_WIDTH,
    CANVAS_HEIGHT,
  ],
  ignoreInput = false,
}) {
  const animationFrameRef = useRef(0)

  const currentAnimationIteration = useRef(0)

  const keyboardAgitator = useKeyboardAgitator({ ignoreInput })

  const webGL = useMemo(() => isWebGLCapable(), [])

  const {
    uBackground,
  } = assets

  useEffect(() => {
    if (!canvasRef.current) return

    if (!webGL) return

    const regl = REGL({
      canvas: canvasRef.current,
      extensions: ['OES_standard_derivatives', 'angle_instanced_arrays'],
    })

    const textureUBackground = regl.texture(uBackground.asset)
    const textureUFeedback = regl.texture()

    const draw = regl({
      viewport: {
        x: 0,
        y: 0,
        w: CANVAS_WIDTH,
        h: CANVAS_HEIGHT
      },
      attributes: {
        aPosition: [
          -1, 1,
          -1, -1,
          1, -1,
          -1, 1,
          1, 1,
          1, -1
        ],
      },
      uniforms: {
        uResolution: [
          regl.context('viewportWidth'),
          regl.context('viewportHeight')
        ],
        uTime: regl.prop('uTime'),
        uLeftAgitation: regl.prop('uLeftAgitation'),
        uRightAgitation: regl.prop('uRightAgitation'),
        uNoiseOffsetLeft: regl.prop('uNoiseOffsetLeft'),
        uNoiseOffsetRight: regl.prop('uNoiseOffsetRight'),
        uBackground: textureUBackground,
        uFeedback: textureUFeedback,
      },
      count: 6,
      frag,
      vert,
    })

    function animLoop() {
      try {
        currentAnimationIteration.current += 1
        
        keyboardAgitator.tick()

        draw({
          uTime: currentAnimationIteration.current,
          uLeftAgitation: keyboardAgitator.getValue('left'),
          uRightAgitation: keyboardAgitator.getValue('right'),
          uNoiseOffsetLeft: keyboardAgitator.getNoiseOffset('left'),
          uNoiseOffsetRight: keyboardAgitator.getNoiseOffset('right'),
        })

        textureUFeedback({
          copy: true
        })

        animationFrameRef.current = requestAnimationFrame(animLoop)
      } catch (e) {
        console.error(e)
      }
    }

    animLoop()

    return () => {
      cancelAnimationFrame(currentAnimationIteration)
    }

  }, [canvasRef, keyboardAgitator, uBackground.asset])
}