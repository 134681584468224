import styled from 'styled-components'
import { breakpoints } from '../../../siteConfig'

// TODO, we should change this to not only
// hide components but avoid rendering them at all
// window.matchMedia('(any-hover: none)') to test for pointer devices

const HiddenOnMobile = styled.div`
  @media (max-width: ${breakpoints.mobile}px) {
    display: none;
  }
`

export default HiddenOnMobile
