import React from 'react'
import CSSReset from './CSSReset'
import CSSTheming from './CSSTheming'

export default function BaseStyles() {
  return (
    <>
      <CSSReset />
      <CSSTheming />
    </>
  )
}