import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import useBlobsWebGL from '../../hooks/useBlobsWebGL/useBlobsWebGL'

const Canvas = styled.canvas`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`

const DEFAULT_CANVAS_WIDTH = 700
const DEFAULT_CANVAS_HEIGHT = 470

export default function Blobs({
  assets,
  ignoreInput,
}) {
  const [
    CANVAS_WIDTH,
    CANVAS_HEIGHT,
  ] = useMemo(() => {
    if (window.innerHeight < 850) {
      const scaleFactor = window.innerHeight / 850
      return [
        DEFAULT_CANVAS_WIDTH * scaleFactor,
        DEFAULT_CANVAS_HEIGHT * scaleFactor,
      ]
    }

    return [
      DEFAULT_CANVAS_WIDTH,
      DEFAULT_CANVAS_HEIGHT
    ]
  }, [window.innerHeight]) 

  const canvasRef = useRef(null)

  useBlobsWebGL({
    assets,
    canvasRef,
    dimensions: [
      CANVAS_WIDTH,
      CANVAS_HEIGHT,
    ],
    ignoreInput
  })
 
  return (
    <Canvas
      ref={canvasRef}
      width={CANVAS_WIDTH}
      height={CANVAS_HEIGHT}
    />
  )
}