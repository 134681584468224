import React from 'react'
import propTypes from 'prop-types'
import ringedOne from '../../images/planets/ringed-1.svg'
import ringedTwo from '../../images/planets/ringed-2.svg'
import unringed from '../../images/planets/planet.svg'
import comet from '../../images/planets/comet.svg'
import styled, { keyframes, css } from 'styled-components'

function getSvgUri({ type }) {
  if (type === 'ringed-1') {
    return ringedOne
  }

  if (type === 'ringed-2') {
    return ringedTwo
  }

  if (type === 'comet') {
    return comet
  }

  return unringed
}


const hover = keyframes`
  from {
    transform: translateY(1rem);
  }

  to {
    transform: translateY(0);
  }
`

const Animated = styled.div`
  animation: ${hover} 2s ${({ offset }) => offset}s ease-in-out both infinite alternate;
  @media (prefers-reduced-motion) {
    animation: none;
  }
`

function getTransform({
  x,
  y,
  scale,
}) {
  return css`
    transform: translate(${x},${y}) scale(${scale});
  `
}

const Positioned = styled.div`
  position: absolute;
  top: 0;
  transform-origin: center;
  ${getTransform};
`

function Planet({
  type,
  animationOffset,
  scale,
  position,
}) {
  const uri = getSvgUri({ type })

  return (
    <Positioned
      x={position.x}
      y={position.y}
      scale={scale}
    >
      <Animated
        offset={animationOffset}
      >
        <img
          src={uri}
          alt=""
        />
      </Animated>
    </Positioned>
  )
}

Planet.propTypes = {
  animationOffset: propTypes.number,
  scale: propTypes.number,
  position: propTypes.shape({
    x: propTypes.string,
    y: propTypes.string,
  }),
  type: propTypes.oneOf([
    'ringed-1',
    'ringed-2',
    'unringed',
    'comet',
  ]),
}

Planet.defaultProps = {
  type: 'ringed-1',
  scale: 1,
  animationOffset: 0,
  position: { x: '0px', y: '0px' },
}

export default Planet