import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useGlobalState from '../../hooks/useGlobalState'
import { createFullScreenFlex } from '../StyleUtils'
import useBlobsSeceneAssets from '../../hooks/useBlobsSceneAssets'
import Blobs from '../Blobs'
import Subtitles from '../Subtitles'

const SceneWrapper = createFullScreenFlex({ elementType: 'section' })


const RowItem = styled.div`
  margin: 0 0.75rem;
`

const FlexColumnSceneWrapper = styled(SceneWrapper)`
  flex-direction: column;
`

const introSubs = [
  { id: 'SPLASH_WELCOME', text: 'Welcome.', timeout: 5000 },
  { id: 'SPLASH_BREATHE', text: 'Breathe.', timeout: 5000 },
  { id: 'SPLASH_BEGIN', text: '', timeout: null }
]

export default function SplashPage() {

  const { playBgMusic } = useGlobalState()

  const [activeSubtitleIndex, setActiveSubtitleIndex] = useState(0)

  const {
    id: subtitleId,
    timeout,
  } = introSubs[activeSubtitleIndex]

  const timeoutRef = useRef(0)

  useEffect(() => {
    if (!timeout) return

    timeoutRef.current = setTimeout(() => {
      setActiveSubtitleIndex(activeSubtitleIndex + 1)
    }, timeout)
  }, [activeSubtitleIndex, timeout])

  const handleClick = useCallback(() => {
    playBgMusic()
  }, [])

  const { assets, allLoaded } = useBlobsSeceneAssets()

  if (!allLoaded) return null

  return (
    <FlexColumnSceneWrapper>
      <RowItem>
        <Blobs
          assets={assets}
          ignoreInput
        />
      </RowItem>
      <RowItem>
        <Subtitles
          onCTAClick={handleClick}
          activeSubtitles={subtitleId}
          subtitles={introSubs}
          transitionDuration={1}
          ignoreMinHeight
        />
      </RowItem>
    </FlexColumnSceneWrapper>
  )
}
