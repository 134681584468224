import useEnhancedReducer from '../useEnhancedReducer'
import {
  ACTION_SELECT_GENDER,
  ACTION_SET_GLOBAL_VOLUME,
  ACTION_PLAY_AUDIO,
  ACTION_STOP_AUDIO,
  ACTION_REGISTER_AUDIO,
  ACTION_SET_ACTIVE_SUBTITLES,
  ACTION_REGISTER_SUBTITLES,
  ACTION_SET_ACTIVE_CHAPTER,
  ACTION_SHOW_NARRATION_UI,
  ACTION_HIDE_NARRATION_UI,
  ACTION_RESET_STATE,
  ACTION_REGISTER_KEYBOARD_INPUT,
  ACTION_CLEAR_KEYBOARD_INPUT,
  ACTION_SET_CURRENT_SCENE,
} from './types'

const initState = {
  isLoading: false,
  selectedGender: null,
  stateKey: 0,
  globalVolume: 0.75,
  audio: {},
  subtitles: [],
  activeChapter: null,
  activeSubtitles: null,
  narrationUI: null,
  keyboardTouched: false,
  currentScene: '/',
}

function reducer(state, { type, payload }) {
  const newState = {
    ...state,
    stateKey: state.stateKey + 1,
  }

  if (type === ACTION_SELECT_GENDER) {
    return {
      ...newState,
      selectedGender: payload,
    }
  }

  if (type === ACTION_SET_GLOBAL_VOLUME) {
    return {
      ...newState,
      globalVolume: payload,
    }
  }

  if (type === ACTION_PLAY_AUDIO) {
    return {
      ...newState,
      audio: {
        ...newState.audio,
        [payload]: {
          ...newState.audio[payload],
          isPlaying: true,
        },
      },
    }
  }

  if (type === ACTION_STOP_AUDIO) {
    return {
      ...newState,
      audio: {
        ...newState.audio,
        [payload]: {
          ...newState.audio[payload],
          isPlaying: false,
        },
      },
    }
  }

  if (type === ACTION_REGISTER_AUDIO) {
    return {
      ...newState,
      audio: {
        ...newState.audio,
        [payload]: {
          isPlaying: false,
        },
      },
    }
  }

  if (type === ACTION_REGISTER_SUBTITLES) {
    return {
      ...newState,
      subtitles: payload,
    }
  }

  if (type === ACTION_SET_ACTIVE_SUBTITLES) {
    return {
      ...newState,
      activeSubtitles: payload,
    }
  }

  if (type === ACTION_SET_ACTIVE_CHAPTER) {
    return {
      ...newState,
      activeChapter: payload,
    }
  }

  if (type === ACTION_SHOW_NARRATION_UI) {
    return {
      ...newState,
      narrationUI: payload,
    }
  }

  if (type === ACTION_HIDE_NARRATION_UI) {
    return {
      ...newState,
      narrationUI: null,
    }
  }

  if (type === ACTION_RESET_STATE) {
    return {
      ...initState,
      audio: {
        ...Object.keys(newState.audio).reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              isPlaying:
                key === 'narration' ? false : newState.audio[key].isPlaying,
            },
          }),
          {},
        ),
      },
    }
  }

  if (type === ACTION_REGISTER_KEYBOARD_INPUT) {
    return {
      ...newState,
      keyboardTouched: true,
    }
  }

  if (type === ACTION_CLEAR_KEYBOARD_INPUT) {
    return {
      ...newState,
      keyboardTouched: false,
    }
  }

  if (type === ACTION_SET_CURRENT_SCENE) {
    return {
      ...newState,
      currentScene: payload,
    }
  }

  return {
    ...state,
  }
}

export default function useGlobalStateReducer() {
  const [state, ...rest] = useEnhancedReducer(reducer, initState)
  return [state, ...rest]
}
