import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/700.css'

import { createGlobalStyle } from 'styled-components'
import { theming } from '../../../siteConfig'

export default createGlobalStyle`
  :root {
    --colorPrimary: ${theming.colorPrimary};
    --colorSecondary: ${theming.colorSecondary};
    --colorTertiary: ${theming.colorTertiary};
    --colorWhite: ${theming.colorWhite};

    --fontFamilyPrimary: ${theming.fontFamilyPrimary};
    --fontWeightPrimary: ${theming.fontWeightPrimary};
    --fontFamilySecondary: ${theming.fontFamilySecondary};
    --fontWeightSecondary: ${theming.fontWeightSecondary};

    --defaultFontColor: var(--colorWhite);

    .font-family-primary {
      font-family: var(--fontFamilyPrimary);
      font-weight: var(--fontWeightPrimary);
      color: var(--defaultFontColor);
    }

    .font-family-secondary {
      font-family: var(--fontFamilySecondary);
      font-weight: var(--fontWeightSecondary);
      color: var(--defaultFontColor);
    }

    body {
      background-color: var(--colorPrimary);
    }
  }
`
