import React from 'react'
import styled from 'styled-components'
import Blobs from '../Blobs'
import Narration from '../Narration'
import { createFullScreenFlex } from '../StyleUtils'
import useBlobsSeceneAssets from '../../hooks/useBlobsSceneAssets'
import Transitioned from '../Transitioned'
import { AnimatePresence } from 'framer-motion'
import useUpdateCurrentScene from '../../hooks/useUpdateCurrentScene'
const SceneWrapper = createFullScreenFlex({ elementType: 'section' })

const FlexColumnSceneWrapper = styled(SceneWrapper)`
  flex-direction: column;
`

export default function Experience({ path }) {
  const {
    allLoaded,
    assets,
  } = useBlobsSeceneAssets()

  useUpdateCurrentScene()

  return (
    <FlexColumnSceneWrapper>
      <Narration />
      {
        allLoaded && (
          <AnimatePresence exitBeforeEnter>
            <Transitioned key="the-blobs">
              <Blobs assets={assets} />
            </Transitioned>
          </AnimatePresence>
        )
      }
    </FlexColumnSceneWrapper>
  )
}