import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import VolumeIcon from './VolumeIcon'
import Transitioned from '../Transitioned'

const ComponentWrapper = styled.div`
  margin: 0 3rem;
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  margin: 0.1rem 0.5rem 0;
`

const InputWrapper = styled.div`
  position: relative;
  width: 5.75rem;
  height: 2rem;
  cursor: pointer;
`

const Label = styled.label`
  height: 2rem;
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  width: 5.75rem;
  height: 0.125rem;
  transform: translateY(-50%);
  z-index: -1;
  background: var(--colorWhite);
  border-radius: 0.0625rem;
  background-image: linear-gradient(var(--colorTertiary), var(--colorTertiary));
  background-size: ${({ value }) => value * 100}% 100%;
  background-repeat: no-repeat;
`

const Input = styled.input`
  appearance: none;
  height: 2rem;
  width: 5.75rem;
  position: absolute;
  left: 0;
  top: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 0.4375rem;
    width: 0.4375rem;
    border-radius: 50%;
    border: none;
    background: var(--colorTertiary);
  }

  &::-moz-range-thumb {
    height: 0.4375rem;
    width: 0.4375rem;
    border-radius: 50%;
    border: none;
    background: var(--colorTertiary);
  }

  &::-ms-thumb {
    height: 0.4375rem;
    width: 0.4375rem;
    border-radius: 50%;
    border: none;
    background: var(--colorTertiary);
  }

  &::-webkit-slider-runnable-track,
  &::-moz-range-track,
  &::-ms-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`

export default function VolumeControl({ onChange, volume, visible }) {
  return (
    <AnimatePresence>
      {visible && <Transitioned
        key="volume-control"
      >
        <ComponentWrapper>
          <IconWrapper>
            <VolumeIcon volume={volume} />
          </IconWrapper>
          <InputWrapper>
            <Input
              type="range"
              name="volume"
              id="volume"
              onChange={({ target: { value } }) => onChange(value)}
              value={volume}
              min="0"
              max="1"
              step={0.01}
            />
            <Label htmlFor="volume" value={volume} />
          </InputWrapper>
        </ComponentWrapper>
      </Transitioned>}
    </AnimatePresence>
  )
}

